<template>
  <div class="stack-detail">
    <transition name="fade" mode="out-in">
      <div class="stack-detail__inner">
        <div class="stack-detail__content">
          <div>
            Обратите внимание, что Вы находитесь внутри заблокированной инфраструктуры под номером
            <main-label color="primary" class="status">
              {{ `#${id}` }}
            </main-label>
            причины блокировки можно узнать
            <plain-button color="primary" size="medium" @click="addNewTicket">
              {{ $t('new_ticket') }}
            </plain-button>
          </div>
          <transition name="slide-fade">
            <router-view :id="id" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import storeMixin from '@/layouts/Support/mixins/index.js';
import MainLabel from '@/components/Labels/MainLabel';
export default {
  name: 'WrapperStat',
  components: { MainLabel },
  mixins: [storeMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    currentRoute() {
      return this.$router.currentRoute.name;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<i18n>
{
  "ru": {
    "new_ticket": "тут"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.stack-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
    &-bottom {
    margin-bottom: 0.5rem;
    }
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
